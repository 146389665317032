$groen1: rgb(23, 88, 74);
$groen2: rgb(11, 143, 140);
$groen3: rgb(151, 204, 204);

@import url('https://fonts.googleapis.com/css?family=Open+Sans|Raleway&display=swap');

@mixin border {
    margin: 1rem;
    border: 3px solid $groen1;
    position: relative;
}

@mixin telefoon {
    @media (max-width: 599px) { @content; }
}

@mixin tablet-portrait {
    @media (min-width: 600px) { @content; }
}

@mixin tablet-landscape {
    @media (min-width: 900px) { @content; }
}

@mixin desktop-up {
    @media (min-width: 1050px) { @content; }
}
