@import "../../css/variables";

.agenda-select {
    @include border;
    display: flex;

    div {
        // width: 50%;
        position: relative;

        select {
            position: absolute;
        }
    }

    &-locatie {

    }

    &-zaal {

    }
}

.agenda-bar1 {
    @include border;

    h4 {
        text-align: center;
        color: $groen1;
        font-size: 1.5rem;
        margin: 1rem 0;
    }
}

.agenda-bar2 {
    @include border;
    background-color: $groen1;

    h4 {
        text-align: center;
        color: white;
        font-weight: 100;
        font-style: italic;
        font-size: 1.1rem;
        margin: 1rem 0;
    }
}