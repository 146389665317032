@import "../../css/variables";

.info {
    h3 {
        color: $groen1;
        padding: 1rem;
        border-bottom: 1px solid $groen2;
        width: 50%;
    }

    p {
        margin: 1rem;
    }
}