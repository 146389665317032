@import "../../css/variables";

.form {
    @include border;
    padding: 1rem;

    &-header {
        h3, p {
            margin-top: 0;
            margin-bottom: 0.5rem;
        }
    }

    &-form {
        &-label {
            display: block;
            margin-bottom: 0.5rem;
            input, textarea {
                display: block;
            }
        }
    }
}
