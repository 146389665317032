@import "../../css/variables";

.ruimten {
    @include border;
    padding: 1rem;

    &-header {
        display: flex;

        &-section {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            width: 25%;

            h3 {
                color: $groen1;
                font-size: 1.8rem;
                margin: 0;
                margin-bottom: 0.5rem;
                padding: 0;
                line-height: 1;
            }
        
            p {
                background-color: $groen2;
                width: 80%;
                color: white;
                box-sizing: border-box;
                padding: 0.3rem 0.5rem;
                margin: 0.3rem 0;
                font-size: 1.2rem;
                z-index: 1;
                position: relative;

                &:after {
                    left: 100%;
                    top: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(136, 183, 213, 0);
                    border-left-color: $groen2;
                    border-width: 0.95em;
                    margin-top: -0.96em;
                }
            }
        }

        .slider {
            width: 50%;
        }

        &-link {
            width: 25%;
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: center;

            a {
                width: 6.5rem;
                height: 6.5rem;
                margin-left: 1rem;
                border: 2px solid $groen2;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
