@import "../../css/variables";

h2 {
    margin-left: 2rem;
}

nav {
    border-bottom: 2px solid $groen1;

    ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 1rem;
        list-style-type: none;
        padding-left: 0;
        margin: 1rem;
        margin-bottom: 2rem;
    }

    li {
        position: relative;
    }

    a {
        display: block;
        color: $groen1;
        text-decoration: none;
        border: 3px solid $groen1;
    }

    a:hover::before, a.selected::before {
        background-color: $groen1;
    }

    a::before {
        position: absolute;
        bottom: -1.2em;
        left: calc(50% - 2.5rem);
        content: "";
        display: block;
        background-color: lightgrey;
        width: 5rem;
        height: 8px;
    }

    a::after {
        position: absolute;
        bottom: -2em;
        left: calc(50% - 1px);
        display: none;
        content: "";
        width: 2px;
        height: 0.8em;
        background-color: $groen1;
    }

    a.selected::after, a:hover::after {
        display: block;
    }

    h3 {
        text-align: center;
        font-size: 1.3rem;
        margin: 0.7rem 0;
    }
}

.footer {
    @include border;

    h4 {
        text-align: center;
        color: $groen1;
        font-size: 1.5rem;
        margin: 1rem 0;
    }
}
