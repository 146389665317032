.slider {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    img {
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    &-dots {
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        margin-left: 6px;
        span {
            box-shadow: 2px 2px 12px 0px rgba(0,0,0,0.75);
            width: 12px;
            background-color: white;
            height: 12px;
            border-radius: 12px;
            display: block;
            margin-right: 6px;

            &:hover {
                cursor: pointer;
                box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
            }
        }
    }
}