@import "./css/variables";

html, body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
}

main {
    display: block;
    margin: 0 auto;
    padding: 0 1em;
    max-width: 950px;
    margin-top: 40px;
}

img {
    width: 100%;
    height: 100%;
    display: block;
}

header {
    display: grid;
    grid-template-areas:
        "logo ."
        "logo titel"
        "logo navigatie"
        ". .";

    border-bottom: 2px solid $groen1;

    @include telefoon {
        grid-template-columns: 6.6em auto;
        grid-template-rows: 2.2em 2.2em 2.2em 1.4em;
        grid-column-gap: 3.2em;

        img {
            height: 6.6em;
        }

        h1 {
            font-size: 1.5em;
        }
    }

    @include tablet-portrait {
        grid-template-columns: 6.6em auto;
        grid-template-rows: 2.2em 2.2em 2.2em 1.4em;
        grid-column-gap: 3.2em;

        img {
            height: 6.6em;
        }

        h1 {
            font-size: 1.5em;
        }

        li {
            margin-right: 1.5rem;

            a {
                font-size: 1.2em;

                &::before {
                    top: 1.5em;
                    height: 7px;
                }
        
                &::after {
                    bottom: -1.5rem;
                    height: 0.8em;
                }
            }
    
            
        }

        li::before {
            font-size: 1.2em;
            top: 1;
            right: -0.9em;
            margin-left: 0.7rem;
        }

    }

    @include tablet-landscape {
        grid-template-columns: 9.9em auto;
        grid-template-rows: 3.3em 3.3em 3.3em 2em;
        grid-column-gap: 5em;

        img {
            height: 9.9em;
        }

        h1 {
            font-size: 2.5em;
        }

        li {
            margin-right: 2rem;

            &::before {
                font-size: 1.5em;
                top: 1;
                right: -0.9em;
                margin-left: 0.7rem;
            }

            a {
                font-size: 1.5em;

                &::before {
                    top: 1.5em;
                    height: 8px;
                }
        
                &::after {
                    bottom: -2rem;
                    height: 0.9em;
                }
            }
    
            
        }

    }

    img {
        grid-area: logo;
    }

    a {
        text-decoration: none;
        
    }

    h1 {
        grid-area: titel;
        display: block;
        align-self:start;
        margin: 0;
        color: $groen1;
    }
    

    ul {
        grid-area: navigatie;
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0;
        align-self: end;
        position: relative;
    }

    li {
        position: relative;

        a {
            display: block;
            text-decoration: none;
            color: black;
        }

        a:hover::before, a.pagina::before {
            background-color: $groen1;
        }
    
        a::before {
            position: absolute;
    
            left: 0px;
            content: "";
            display: block;
            background-color: lightgrey;
            width: 100%;
    
        }
    
        a::after {
            position: absolute;
            left: calc(50% - 1px);
            display: none;
            content: "";
            width: 2px;
            background-color: $groen1;
        }
    
        a.pagina::after, a:hover::after {
            display: block;
        }
    }

    li:last-child {
        margin-right: 0;
    }

    li::before {
        content: "|";
        display: block;
        position: absolute;
    }

    li:last-child::before {
        display: none;
    }

}
